import React from "react";
import Layout from "./src/components/layout";

import { EpisodeProvider } from "./src/components/context";
import { ThemeProvider, Styled } from "theme-ui";
import theme from "./src/gatsby-plugin-theme-ui/index";
import { SkipNavLink } from "@reach/skip-nav";

export const wrapPageElement = ({ element, props }, options) => {
  const episodeSlug = options.episodeSlug ? options.episodeSlug : "puntata";
  return (
    <ThemeProvider theme={theme}>
      <EpisodeProvider>
        <Styled.root>
          <SkipNavLink />
          {true && (
            <Layout {...props}>
              {props.location.pathname.includes(episodeSlug)}
              {element}
            </Layout>
          )}
        </Styled.root>
      </EpisodeProvider>
    </ThemeProvider>
  );
};
