export default {
  useCustomProperties: true,
  initialColorMode: "dark",
  colors: {
    text: "rgba(255, 255, 255, 0.9)",
    background: "#1A2232",
    backgroundLighten10: "#232B3B",
    backgroundLighten20: "#2C3648",
    primaryDarken: "#ffc107",
    primary: "#ffc107",
    primaryLighten10: "#ffc107",
    primaryLighten50: "#ffc107",
    primaryLighten70: "#ffc107",
    secondary: "#85FFD0",
  },
};
