export default {
  // podcastSeason: "1",
  headerImageHeight: [300, 400],
  spotifyUrl: "https://open.spotify.com/show/6njkISCQLtT5QHG6qjnMLJ",
  applePodcastsUrl:
    "https://podcasts.apple.com/us/podcast/le-notizie-della-illy/id1532217649?uo=4",
  googlePodcastsUrl:
    "https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvNDU1ODc0NS9lcGlzb2Rlcy9mZWVk",
  youtubeUrl: "https://www.youtube.com/@notizieilly",
  youtubePodcastsUrl:
    "https://www.youtube.com/playlist?list=PLDaRQZJMDHSSpDNMLe7E5kTbUeU_hgck6",
};
