// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-podcast-src-templates-episode-js": () => import("./../../../../gatsby-theme-podcast/src/templates/episode.js" /* webpackChunkName: "component---gatsby-theme-podcast-src-templates-episode-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-credits-js": () => import("./../../../src/pages/open-source-credits.js" /* webpackChunkName: "component---src-pages-open-source-credits-js" */),
  "component---src-pages-tutte-le-puntate-js": () => import("./../../../src/pages/tutte-le-puntate.js" /* webpackChunkName: "component---src-pages-tutte-le-puntate-js" */)
}

