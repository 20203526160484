module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Le notizie della illy","short_name":"notizieilly","start_url":"/","background_color":"#131925","theme_color":"#ffc107","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d42f7007c7ebd5d96a07815f1c091fa"},
    },{
      plugin: require('../../gatsby-theme-podcast/gatsby-browser.js'),
      options: {"plugins":[],"markdownPath":"../data/content/episodes","episodeSlug":"puntata"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lenotiziedellailly.it","stripQueryString":true},
    }]
