import React from "react";

const Footer = () => (
  <div className="footer">
    {false && (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ffc107"
          fill-opacity="1"
          d="M0,64L80,90.7C160,117,320,171,480,176C640,181,800,139,960,138.7C1120,139,1280,181,1360,202.7L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    )}
    <p>
      L'attualità non è mai troppo <strong>grande</strong> per essere spiegata
      ai più <strong>piccoli</strong>!
    </p>
  </div>
);

export default Footer;
