/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import Navigation from "./navigation";

import "../scss/lenotiziedellailly.scss";

import { jsx, Box, Container } from "theme-ui";
import Footer from "./footer";

function Layout({ children }) {
  return (
    <React.Fragment>
      {false && (
        <div
          className="lenotiziedellailly-sostienici"
          style={{
            backgroundColor: "rgb(255, 193, 7)",
            color: "rgb(26, 34, 50)",
          }}
        >
          <p style={{ padding: 7, marginBottom: 0 }}>
            <strong>Sostieni</strong> Le notizie della illy!
          </p>
        </div>
      )}
      <Box style={{ margin: "auto" }}>
        <Container
          sx={{
            p: 0,
            display: "flex",
            flexDirection: ["column", "row"],
            flexGrow: "1",
          }}
          style={{ maxWidth: 1310 }}
        >
          <Navigation eventTypes="click" />
          <main sx={{ width: "100%", ml: [0, 0, 0, 5] }}>{children}</main>
        </Container>
      </Box>
      <Footer />
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
